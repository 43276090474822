/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


.fallback-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.fallback-header {
  text-align: center;
}

.fallback-logo {
  width: 80px;
  height: 80px;
}

.fallback-content {
  display: flex;
  align-items: center;
  margin-top: 20px;
}


.fallback-text {
  margin-left: 10px;
  font-size: 18px;
  color: #888;
}



.fallback-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #ff9900; /* Change the border color to orange */
  border-radius: 50%;
  animation: spinner 0.8s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}



.top-header-area {
  color: rgba(24, 24, 24, 0.6);
  background: #000 !important;
}
.top-header-area {
  color: #fff;
  font-size: 15px;
  display: block;
  background: #000 !important;
  text-align: center;
  padding: 10px 10px;
  top: 0px;
  left: 0px;
  right: 0px;
  /* position: fixed; */
  z-index: 99999;
  height: 50px;
}

#token-disp  a{
  color: #f7961c !important;
}

/* Responsive Quries----- Wider than desktop */
@media (min-width: 1200px) {  }

/* Landscape phone to desktop */
@media (max-width: 1199px) {  }

/* Landscape phone to landscape tablet */
@media (max-width: 979px) {  }

/* Small desktop */
@media (min-width: 980px) and (max-width: 1199px) {  }

/* Portrait tablet to landscape tablet */
@media (min-width: 768px) and (max-width: 979px) {  }

/* Landscape phone to portrait tablet */
@media (max-width: 767px) { 
    .top-header-area {
  font-size: 10px;
}
 }

/* Landscape phones and smaller */
@media (max-width: 480px) { 
  .top-header-area {

  font-size: 10px;
}
 }



 .grid-chain {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  --g: 3vw;
  grid-gap: var(--g);
}

.grid-chain>* {
  width: 75px;
  height:75px;
  aspect-ratio: 2 / 1;
  /* border: solid 1px gray; */
  /* background: lightgray; */
  position: relative;
}

.grid-chain>*::before {
  content: '';
  height: var(--g);
  width: 1px;
  background-image: linear-gradient(#F7961C 0 50%, transparent 50% 100%);
  background-size: auto calc(var(--g) / 4);
  position: absolute;
  z-index: -1;
  bottom: 100%;
  left: 50%;
}

.grid-chain>*:nth-child(1)::before,
.grid-chain>*:nth-child(2)::before,
.grid-chain>*:nth-child(3)::before {
  display: none;
}

.grid-chain>*::after {
  content: '';
  width: var(--g);
  height: 1px;
  background-image: linear-gradient(to right, transparent 0 50%, #F7961C 50% 100%);
  background-size: calc(var(--g) / 4);
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 100%;
}

.grid-chain>*:nth-child(3n)::after,
.grid-chain>*:last-child::after {
  display: none;
  ;
}

.grid-chain-content{
  display: flex;
  justify-content:center;
  align-items: center;
}
.grid-chain-content .address{
  font-size: 10px;
  margin-bottom: 0px !important;
}

.grid-chain-content .price{
  font-size: 12px;
  font-weight: bold;
}

.grid-status-icon-online{
    width: 20px;
    height: 5px;
    background-color: green;
    border-radius: 10px;


}


.grid-amount{
    width:70px;
    height: 20; 
    line-height: 1em; 
    font-size: 12px;
    text-overflow: ellipsis;
    margin-bottom: 12px;
    cursor: pointer;
    word-break: break-all;
    overflow:hidden;
    white-space: nowrap;
}
.grid-amount:hover{
    overflow: visible; 
    white-space: normal;
    height:auto;  /* just added this line */
}